import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import {
  Checkbox,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import portalData from "../../../utils/constants/portal-data";
import CustomButton from "../CustomButton";
import "./style.scss";
import "./border.scss";
import SelectInput from "../SelectInput";
import HiringCard from "../HiringCard";
import studentsData from "../../../utils/constants/studentsData.json";

const MainContainer = ({ isFilterBlurred }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [bootcamp, setBootcamp] = useState();
  const [openOverlay, setOpenOverlay] = useState(-1);
  const [transform, setTransform] = useState({ x: 0, y: 0 });
  const [filteredStudents, setFilteredStudents] = useState([]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));

  const bootcampColor = useMemo(() => {
    const { palette } = theme;
    switch (bootcamp) {
      case "FSW":
        return palette.primary.main;
      case "FSD":
        return palette.fsd.main;
      case "UIX":
        return palette.uix.main;
      default:
        return palette.primary.main;
    }
  }, [bootcamp]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedBootcamp = queryParams.get("bootcamp");
    setBootcamp(selectedBootcamp);

    const filtered = studentsData.filter(
      (student) => student.bootcamp === selectedBootcamp
    );
    setFilteredStudents(filtered);
  }, [location]);

  return (
    <div className="hiring-portal-wrapper">
      <div className="hiring-portal-container">
        <div
          className={`hiring-portal-filters-container ${bootcamp?.toLowerCase()}-container`}
          style={{ width: "100%" }}
        >
          <div className={`filters-content `}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack flexDirection="row">
                <Typography
                  sx={{ transition: ".4s ease-out" }}
                  variant="h1"
                  fontWeight={900}
                  fontSize={isSmall ? (isSM ? 12 : 16) : 24}
                  color={bootcampColor}
                >
                  &gt;
                </Typography>
                <Typography
                  sx={{ transition: ".4s ease-out" }}
                  ml={2}
                  variant="h1"
                  fontWeight={900}
                  fontSize={isSmall ? (isSM ? 12 : 16) : 24}
                  color={bootcampColor}
                >
                  {portalData ? portalData[bootcamp]?.title : "bootcamp"}
                </Typography>
                <Typography
                  ml={isSmall ? 1 : 2}
                  variant="h1"
                  fontWeight={900}
                  fontSize={isSmall ? (isSM ? 12 : 16) : 24}
                  color="white"
                >
                  Hiring Portal
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <div className="se-dot se-dot-white" />
                <div className="se-dot se-dot-grey" />
                <div className={`se-dot se-dot-${bootcamp?.toLowerCase()}`} />
              </Stack>
            </Stack>
            <Typography
              mb={bootcamp === "UIX" ? 2 : 5}
              mt={2}
              variant="h5"
              fontWeight={800}
              fontSize={isSmall ? (isSM ? 10 : 13) : 18}
              color="#888888"
            >
              <span style={{ marginRight: "7px" }}>//</span>{" "}
              {portalData[bootcamp]?.below_title}
            </Typography>
            <div
              className={`filters-wrapper ${isFilterBlurred ? "blurred" : ""}`}
            >
              <Stack
                my={2}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  fontWeight={800}
                  textTransform="uppercase"
                  fontSize={isSM ? 11 : 17}
                  variant="h6"
                  color="#A5A6A9"
                >
                  Filter By
                </Typography>
                <Typography
                  fontWeight={800}
                  fontSize={isSM ? 11 : 14}
                  variant="h6"
                  color="#A5A6A9"
                  sx={{ cursor: "pointer" }}
                >
                  reset all
                </Typography>
              </Stack>
              <div className="filter-by-container">
                <div
                  style={{
                    width: !isSmall ? "49%" : "100%",
                    flexBasis: !isSmall ? "49%" : "100%",
                    padding: !isSmall ? "0 0 0 10px" : "5px 0",
                    marginTop: !isSmall ? 0 : 20,
                  }}
                >
                  <SelectInput label="Project Type" />
                </div>
                <div
                  style={{
                    width: !isSmall ? "49%" : "100%",
                    flexBasis: !isSmall ? "49%" : "100%",
                    padding: !isSmall ? "0 0 0 10px" : "5px 0",
                    marginTop: !isSmall ? 0 : 20,
                  }}
                >
                  <SelectInput label="Technologies Used" />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ color: "white" }}
                  color="secondary"
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  my={1}
                  mr={isSM ? 1 : 2}
                  variant={"h5"}
                  fontSize={isSM ? 11 : 16}
                >
                  Only Favorites
                </Typography>
              </div>
              <Stack mt={2} mb={1} flexDirection="row" justifyContent="center">
                <CustomButton
                  className={`${bootcamp?.toLowerCase()}-button`}
                  sx={{
                    height: isSM ? "35px" : "40px",
                    color: "black",
                    minWidth: isSM ? "150px" : "220px",
                    fontSize: isSM ? "12px" : "16px",
                  }}
                >
                  Show Results
                </CustomButton>
              </Stack>
            </div>
            <Grid item xs={12} my={1} mt={4}>
              <Typography
                sx={{ fontWeight: "400" }}
                variant={"h6"}
                fontSize={isSmall ? (isSM ? 10 : 13) : 15}
              >
                Can't find what you're looking for? Some students might have
                in-depth knowledge in specific technologies and didn't use them
                in the final project. Please reach out to{" "}
                <a href="mailto: hire@sefactory.io" style={{ color: `unset` }}>
                  hire@sefactory.io
                </a>
                .
              </Typography>
            </Grid>
          </div>
        </div>
        <div
          onClick={() => {
            setOpenOverlay(-1);
            setTransform({ x: 0, y: 0 });
          }}
          className={`card-overlay ${openOverlay !== -1 && "open"}`}
        />
        <Grid container spacing={isSM ? 0 : isSmall ? 2 : 5} my={3}>
          {filteredStudents.map((props, index) => {
            return (
              <Grid
                sx={{
                  transition: ".3s ease-out",
                  position: "relative",
                  zIndex: openOverlay === index ? 100 : 0,
                  transform:
                    openOverlay === index
                      ? `translate(${transform.x}px, ${transform.y}px)`
                      : "translate(0px,0px)",
                }}
                key={`card-${props.id}`}
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                mt={2}
              >
                <HiringCard key={props.id} {...props} bootcamp={bootcamp} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default MainContainer;
