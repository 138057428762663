import { Route, Switch } from "react-router-dom";
import LandingPage from "./lib/ui/pages/Landing Page";
import "./lib/styles/app.css";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={LandingPage} />
      </Switch>
    </div>
  );
}

export default App;
