import Footer from "../../base/Footer";
import MainContainer from "../../base/MainContainer";
import Navbar from "../../base/Navbar";
import "./style.scss";

const LandingPage = () => {
  return (
    <div className="main-wrapper">
      <div>
        <Navbar />
        <MainContainer isFilterBlurred={true} />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
