import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "./style.css";

const SelectInput = ({ label }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        className="light-grey-bg"
        label={label}
        sx={{
          "& .MuiFilledInput-root": {
            borderRadius: "10px",
            background: "white",
          },
          "& .MuiFilledInput-underline:before": {
            borderBottom: "none !important",
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none !important",
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectInput;
