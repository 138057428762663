import React from "react";
import ReactDOM from "react-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import "./lib/styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import { SE_LIGHT_GREY, SE_GREEN } from "./lib/styles/color";
import { ModalProvider } from "mui-modal-provider";
import { CssBaseline } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      main: SE_GREEN,
    },
    secondary: {
      main: SE_LIGHT_GREY,
    },
    uix: {
      main: "#FB508E",
    },
    fsd: {
      main: "#9864DA",
    },
  },
  typography: {
    fontFamily: "Noto Sans Mono, monospace",
  },
});

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <BrowserRouter>
//     <ThemeProvider theme={Theme}>
//       <App />
//     </ThemeProvider>
//   </BrowserRouter>
// );

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={Theme}>
      <CssBaseline>
        <ModalProvider>
          <App />
        </ModalProvider>
      </CssBaseline>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
