import React, { useState } from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import YouTube from "react-youtube";
import SEButton from "../SEButton";
import CustomButton from "../CustomButton";
import { SE_GREY } from "../../../styles/color";
import "./style.scss";

const HiringDialog = ({
  calendly,
  youtubeId,
  name,
  github,
  pdf,
  onCancel,
  images,
  projectURL,
  hiringStatus,
  bootcamp,
  behance,
  ...props
}) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Dialog {...props} className={"card-modal-dialog"}>
      <div
        className={`card-modal-main card-modal-main-${bootcamp?.toLowerCase()}`}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ display: "flex", placeContent: "space-between" }}>
              <Typography
                variant={"h4"}
                fontWeight={"bolder"}
                sx={{ fontSize: "30px" }}
              >
                {name}
              </Typography>
              <ClearIcon
                style={{ cursor: "pointer", margin: "auto 0" }}
                onClick={onCancel}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  variant="fullWidth"
                  aria-label="lab API tabs example"
                  sx={{ color: "black" }}
                >
                  <Tab color="red" label="Student Profile" value="1" />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: "24px 0" }}>
                <Grid item xs={12}>
                  <YouTube
                    videoId={youtubeId && youtubeId?.split("/")[3]}
                    height={320}
                    width={"100%"}
                    autoplay={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={"card-modal-footer"}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <SEButton
                          variant={"contained"}
                          fullWidth
                          color={"secondary"}
                          disableElevation
                          href={pdf}
                          target="_blank"
                        >
                          View CV
                        </SEButton>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <CustomButton
                          className={`${bootcamp?.toLowerCase()}-button`}
                          href={calendly}
                          target="_blank"
                          fullWidth
                          disableElevation
                        >
                          Book Interview
                        </CustomButton>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={bootcamp === "FSD" ? 6 : 12}
                        md={bootcamp === "FSD" ? 6 : 12}
                        lg={bootcamp === "FSD" ? 6 : 12}
                      >
                        <SEButton
                          variant={"contained"}
                          color="secondary"
                          sx={{
                            backgroundColor: SE_GREY,
                            color: "white",
                          }}
                          fullWidth
                          href={github}
                          target="_blank"
                          disableElevation
                        >
                          {bootcamp === "UIX"
                            ? "View Portfolio"
                            : "View Github"}
                        </SEButton>
                      </Grid>
                      {bootcamp === "FSD" && (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <SEButton
                            variant={"contained"}
                            color="secondary"
                            sx={{
                              backgroundColor: SE_GREY,
                              color: "white",
                            }}
                            fullWidth
                            href={projectURL}
                            target="_blank"
                            disableElevation
                          >
                            Live Project
                          </SEButton>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default HiringDialog;
