const hiringPortalData = {
  FSW: {
    title: "FSW",
    below_title:
      "find FSW graduates, check their final projects, resumes, githubs and more...",
  },
  UIX: {
    title: "UIX",
    below_title:
      "find UIX graduates, check their final projects, resumes, behance, portfolios, and more...",
  },
  FSD: {
    title: "FSD",
    below_title:
      "find FSD graduates, check their final projects, resumes, githubs and more...",
  },
};

export default hiringPortalData;
