import React, { useState, useRef, useMemo, useCallback } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
  Stack,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import HiringDialog from "../HiringDialog";
import { SE_GREY, SE_MID_GREY } from "../../../styles/color";
import CustomButton from "../CustomButton";
import SEButton from "../SEButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./style.scss";

const HiringCard = ({
  bootcamp,
  id,
  name,
  title,
  projectDescription: description,
  github,
  youtubeId,
  coverImage,
  pdf,
  calendly,
  projectURL,
  favoriteBy,
  avatarImage,
  projectTypes,
  isBlurred,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const optionsIcon = useRef(null);
  const { showModal } = useModal();

  const bootcampColor = useMemo(() => {
    switch (bootcamp) {
      case "FSW":
        return "primary";
      case "FSD":
        return "fsd";
      case "UIX":
        return "uix";
      default:
        return "primary";
    }
  }, [bootcamp]);

  const flipCard = useCallback((e) => {
    setOpen(true);
    e.stopPropagation();
  }, []);

  const handleClick = (e, skipCheck, pressedOn) => {
    if (
      skipCheck ||
      !["a", "button", "svg", "path"].includes(e.target.localName)
    ) {
      const modal = showModal(HiringDialog, {
        calendly,
        youtubeId,
        name,
        github,
        pdf,
        projectURL,
        bootcamp,
        onCancel: () => {
          modal.hide();
        },
      });
    }
  };

  const projectTypeHandle = () => {
    return projectTypes.join(" & ");
  };

  return (
    <div
      className={`hiring-card-main-container hiring-card-main-container-${bootcamp?.toLowerCase()} ${
        isBlurred ? "blurred" : ""
      }`}
      onMouseOver={(e) => {
        if (!("ontouchstart" in window)) {
          setOpen(true);
        }
      }}
      onTouchStart={(e) => {
        if (!("ontouchstart" in window)) {
          setOpen(true);
        }
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
      onTouchEnd={(e) => {
        if (!["a", "button", "svg", "path"].includes(e.target.localName)) {
          setOpen(false);
        }
      }}
    >
      <div className={"hiring-card-container hiring-card-container"}>
        <div
          className={"hiring-card-image-container hiring-card-image-container"}
          style={{ backgroundImage: `url("${coverImage}")` }}
        />
        <div className={"hiring-card-footer"}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <div
                className="avatar-border"
                style={{ borderColor: theme.palette[bootcampColor].main }}
              >
                <Avatar
                  src={avatarImage}
                  alt={name}
                  sx={{ width: 25, height: 25 }}
                />
              </div>
              <Typography variant={"h6"} fontSize={15} fontWeight={"bold"}>
                {name}
              </Typography>
            </Stack>
          </Stack>
        </div>
      </div>
      <div
        onClick={(e) => handleClick(e, false, "Card")}
        className={`hiring-card-information-main-container ${open && "open"}`}
      >
        <div className={"hiring-card-information-container"}>
          <div
            style={{
              padding: "0",
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
              maxHeight: isMD ? "calc(100% - 143px)" : "calc(100% - 96px)",
            }}
          >
            <Typography
              variant={"h6"}
              fontWeight={"bolder"}
              sx={{ color: SE_MID_GREY }}
              fontSize={isSM ? 14 : 16}
            >
              &gt; {projectTypeHandle()}
            </Typography>
            <Typography
              variant={"h5"}
              fontWeight={"bolder"}
              fontSize={isSM ? 18 : 24}
            >
              {title}
            </Typography>
            <div className="small-divider" />
            <div className="hiring-card-project-description">
              <Typography
                mt={1}
                textAlign={"center"}
                variant={"body2"}
                fontSize={isSM ? 12 : 13}
              >
                {description}
              </Typography>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomButton
                disableElevation
                fullWidth
                className={`${bootcamp?.toLowerCase()}-button`}
                sx={{
                  height: "40px",
                  color: "black",
                }}
                onClick={(e) => handleClick(e, true, "About Me Button")}
              >
                About Me
              </CustomButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SEButton
                variant={"contained"}
                color={"secondary"}
                disableElevation
                fullWidth
                href={calendly}
                target="_blank"
                sx={{
                  height: "40px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                isDisabled={true}
              >
                Book Interview
              </SEButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={bootcamp === "FSD" ? 6 : 12}
              lg={bootcamp === "FSD" ? 6 : 12}
            >
              <SEButton
                variant={"contained"}
                color={"secondary"}
                disableElevation
                sx={{
                  height: "40px",
                  backgroundColor: SE_GREY,
                  color: "white",
                }}
                fullWidth
                href={bootcamp === "UIX" ? projectURL : github}
                target="_blank"
                isDisabled={true}
              >
                {bootcamp === "UIX" ? "View Project" : "View Github"}
              </SEButton>
            </Grid>
            {bootcamp === "FSD" && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SEButton
                  variant={"contained"}
                  color={"secondary"}
                  disableElevation
                  sx={{
                    height: "40px",
                    backgroundColor: SE_GREY,
                    color: "white",
                  }}
                  fullWidth
                  href={projectURL}
                  target="_blank"
                  isDisabled={true}
                >
                  Live Project
                </SEButton>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <div className={`hiring-card-flip ${open && "flip-none"}`}>
        <MoreHorizIcon
          ref={optionsIcon}
          onClick={(e) => flipCard(e)}
          sx={{ color: "#A5A6A9" }}
        />
      </div>
    </div>
  );
};

export default HiringCard;
