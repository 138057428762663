import React from "react";
import "./style.scss";
import { Typography } from "@mui/material";
import { SE_GREEN } from "../../../styles/color";

const Footer = () => {
  return (
    <div className={"footer-wrapper"}>
      <div className={"footer-container"}>
        <Typography variant={"h5"} sx={{ color: "#FCFCFD" }}>
          Reach out to Hire
        </Typography>
        <Typography variant={"body1"} sx={{ color: `${SE_GREEN} !important` }}>
          <a href="mailto: hire@sefactory.io" style={{ color: `unset` }}>
            hire@sefactory.io
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
