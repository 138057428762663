import React from "react";
import { Button } from "@mui/material";
import "./style.scss";

const SEButton = ({ children, isDisabled, ...props }) => {
  return (
    <Button
      className={`se-button ${isDisabled ? "disabled" : ""}`}
      sx={{ color: "#363738" }}
      style={{ borderRadius: "0px !important" }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SEButton;
