import React from "react";
import { Stack } from "@mui/material";
import Logo from "../../../../assets/logos/SEF_logo_text.svg";
import "./style.scss";
import BootcampSelect from "../BootcampSelect";

const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="https://sefactory.io/">
        <img className={"logo"} src={Logo} alt="logo" />
      </a>
      <Stack flexDirection="row" alignItems="stretch">
        <BootcampSelect />
      </Stack>
    </nav>
  );
};

export default Navbar;
